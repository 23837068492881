$.Messenger = {};

$.Messenger.init = function(options) {
    $('.messages-count').text( options.new_messages );
    $('#modal-footer').on('shown.bs.modal', function() {
        if ($('#modal-footer #form-messages').length) {
            $.App.uploadform();
        }
    });
};

$.Messenger.create = function(options) {
    $('select.search-user').html('').select2({
        width: '100%',
        placeholder: 'Szukaj...',
        minimumInputLength: 3,
        ajax: {
            url: options.search_user,
            dataType: 'json',
            delay: 1000,
            data: function (params) {
                return {
                    q: params.term
                };
            },
            processResults: function(data) {
                return {
                    results: $.map(data, function(item) {
                        return {
                            text: item.full_name,
                            id: item.id
                        }
                    })
                };
            },
            cache: true
        }
    });
};

$.Messenger.store = function() {
    if ($.App.isset($.App.dataTable.messagestable)) {
        $.App.dataTable.messagestable.ajax.reload();
    }
    $('#modal-footer').modal('hide');
};

$.Messenger.open = function(id) {
    var row = $('#messages_table tr#' + id);
    if (row.hasClass('active')) {
        row.removeClass('active');
        $('.messages-count').text( $('.messages-count').text() - 1 );        
    }
};