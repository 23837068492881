$.User = {};

$.User.table = function(options) {
    $.User.tableFilter = {
        specialization: $('.specialization').val()
    };
    
    options.parameters.ajax = {
        url: '',
        data: function(d) {
            d.specialization = $.User.tableFilter.specialization;
            return d;
        }
    };
    
    $.App.dataTable('#user_table', options.parameters);
    
    $('#filter').click(function() {
        $.User.tableFilter.specialization = $('.specialization').val();
        $.App.dataTable.usertable.ajax.reload();
    });
    
    
};

$.User.select = function(options) {
    
    var action = function($this, $url, $class, $function) {
        var i = $this.find('i'),
            data = {
                user_id: $this.data('id'),
                action: 'attach'
            };

        if (i.hasClass($class)) {
            data.action = 'detach';
            i.removeClass($class);
        } else {
            i.addClass($class);
        }
        
        if ($.App.isset($function)) {
            $function($this);
        }
        
        $.App.submit($(this), false, $url, 'PUT', data);
    };
    
    /**
     * Wyślij zaproszenie do zlecenia
     */
    if ($.App.isset(options.invite)) {
        $(document).on('click', '.select-invite', function() {
            $.App.submit($(this), false, options.invite, 'PUT', $(this).data());
            $(this).parent().html('<small>rozpatruje zaproszenie</small>');
            return false;
        });
    }
    
    /**
     * Dodanie do ulubionych
     */
    if ($.App.isset(options.favorite)) {
        $(document).on('click', '.select-favorite', function() {
            action($(this), options.favorite, 'warning');
            return false;
        });
    }
    
    /**
     * Dopuść do wszystkich zleceń
     */
    if ($.App.isset(options.allow)) {
        $(document).on('click', '.select-allow', function() {
            action($(this), options.allow, 'success', function($this) {
                ($this.find('i').hasClass('success')) ?
                    $this.parents('.select').find('.select-ban').addClass('display-none') :
                    $this.parents('.select').find('.select-ban').removeClass('display-none');
            });
            return false;
        });
    }
    
    /**
     * Zablokuj użytkownika
     */
    if ($.App.isset(options.ban)) {
        $(document).on('click', '.select-ban', function() {
            action($(this), options.ban, 'danger', function($this) {
                if($this.find('i').hasClass('danger')){
                    $this.attr('title', 'Odblokuj copywritera');
                    $this.parents('.select').find('.select-allow').addClass('display-none');
                }else{
                    $this.attr('title', 'Zablokuj copywritera');
                    $this.parents('.select').find('.select-allow').removeClass('display-none');
                }
            });
            return false;
        });
    }
};

$.User.walletStore = function(callback) {
    $('.wallet-amount').text( callback.amount );
    
    if ($.App.isset($.App.dataTable.payhistory)) {
        $.App.dataTable.payhistory.ajax.reload();
    }
    if ($.App.isset($.App.dataTable.paymenthistory)) {
        $.App.dataTable.paymenthistory.ajax.reload();
    }
};

$.User.updateAdress = function() {    
    $("#user_data").modal("hide");
    
    setTimeout(function() {
        $.App.paymentLoad("#load-payment");
    }, 400);    
};