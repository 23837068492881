$.Campaign = {};

$.Campaign.formText = function() {
    $(document).on('click', '#form-text button[type="submit"]', function() {
        $.App.submit($('#form-text'), true, null, null, {
            name: 'status',
            value: $(this).val()
        });
        return false;
    });
    
    $.App.confirmation();
};

$.Campaign.reservation = function(callback) {
    if ($.App.isset(callback)) {
        $('.reservation').html('');
        
        var reservation = $('#text-' + callback.text_id);        
        reservation.find('.reservation').html( callback.link );
        reservation.find('.text-count').text( reservation.find('.text-count').text() - 1 );
        
        $('#text-edit').trigger('click');
    }
};

$.Campaign.companyTextUpdate = function(callback) {
    var $modal = $('#modal-footer');
    
    var newStatus = function(callback) {
        $('#text-table').find('tr#' + callback.id + ' .status').html(callback.status);
    };
    
    if ($.App.isset(callback)) {
        $.App.toastr('success', 'Dane zapisane pomyślnie');
        
        switch(callback.type) {
            case 'accept':
            case 'accept-allow':
                $modal.find('.alert-success').removeClass('display-none');
                $modal.find('.action-button').remove();
                newStatus(callback);
                break;
            case 'not-accept':
                $modal.find('.alert-danger').removeClass('display-none');
                $modal.find('.action-button').remove();
                newStatus(callback);
                break;
            case 'ban':
                $('#text-table').find('tr#' + callback.id).remove();
                $modal.modal('hide');
                break;
        }
    }
};

$.Campaign.copywriterTextUpdate = function(callback) {
    var $modal = $('#modal-footer');
    
    if ($.App.isset(callback)) {
        switch(callback.status) {
            case 0:
                $modal.find('.action-button').remove();
                break;
        }
    }
};

$.Campaign.buttonText = function(options) {
    $('.checkbox-txt-download').click(function() {
        if ($.App.rows_selected_all) {
            $.App.redirect( sprintf(options.ids, $(this).data('type'), $.App.rows_selected) );
        } else {
            if ($.App.rows_selected.length) {
                $.App.redirect( sprintf(options.ids, $(this).data('type'), $.App.rows_selected) );
            } else {
                $.App.toastr('warning', 'Wybierz minimum 1 pozycję');
            }
        }
    });
};

$.Campaign.select = function(options) {

    var action = function($this, $url, $class, $function) {
        var i = $this.find('i'),
            data = {
                text_id: $this.data('id'),
                action: 'attach'
            };

        if (i.hasClass($class)) {
            data.action = 'detach';
            i.removeClass($class);
        } else {
            i.addClass($class);
        }

        if ($.App.isset($function)) {
            $function($this);
        }

        var index = $.inArray($this.data('id'), $.App.forbidden_ids);

        if (index === -1) {
            $.App.forbidden_ids.push($this.data('id'));
            $('#' + $this.data('id')).find('input[type="checkbox"]').prop('disabled', true);
        } else {
            $.App.forbidden_ids.splice(index, 1);
            $('#' + $this.data('id')).find('input[type="checkbox"]').prop('disabled', false);
        }

        $.App.submit($(this), false, $url, 'PUT', data);
    };

    /**
     * Dopuść do wszystkich zleceń
     */
    if ($.App.isset(options.used)) {
        $(document).on('click', '.select-allow', function() {
            action($(this), options.used, 'success');
            return false;
        });
    }
};