$.extend($.fn.DataTable.defaults, {
    lengthMenu: [
        [10, 25, 50, 100],
        [10, 25, 50, 100]
    ],
    iDisplayLength: 50,
    language: {
        sProcessing: '<div class="app-loading"><i class="fa fa-cog fa-spin fa-2x"></i></div>',
        sLengthMenu: 'Pokaż _MENU_ pozycji',
        sZeroRecords: 'Nie znaleziono pasujących pozycji',
        sInfoThousands: ' ',
        sInfo: 'Pozycje od _START_ do _END_ z _TOTAL_ łącznie',
        sInfoEmpty: 'Pozycji 0 z 0 dostępnych',
        sInfoFiltered: '(filtrowanie spośród _MAX_ dostępnych pozycji)',
        sInfoPostFix: '',
        sSearch: 'Szukaj:',
        sUrl: '',
        oPaginate: {
            sFirst: 'Pierwsza',
            sPrevious: 'Poprzednia',
            sNext: 'Następna',
            sLast: 'Ostatnia'
        },
        sEmptyTable: 'Brak danych',
        sLoadingRecords: '<div class="app-loading"><i class="fa fa-cog fa-spin fa-2x"></i></div>',
        oAria: {
            sSortAscending: ': aktywuj, by posortować kolumnę rosnąco',
            sSortDescending: ': aktywuj, by posortować kolumnę malejąco'
        }
    },
    drawCallback: function (settings, json) {
        $('.white-tooltip').tooltip({container:'body'});
    },
    autoWidth: false,
    responsive: true
});