WebFontConfig = {
    google: { families: [ 'Open+Sans:400,300,600,700:latin,latin-ext' ] }
};
(function() {
    var wf = document.createElement('script');
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();

$.fn.modal.Constructor.prototype.enforceFocus = function() {};

$.App = {};
$.App.init = function(options) {
    this.mobile = false;
    this.domain = options.domain;
    this.token = options.token;
    this.load_payment = options.load_payment;
    this.cost = options.cost;
    this.cost.vat = $.App.decimal(this.cost.vat, 2) / 100;
    
    $.ajaxSetup({
        async: false
    });
    $(document).ajaxStart(function() {
        setTimeout(function() {
            $.App.loading('show');
        }, 50);
    });
    $(document).ajaxStop(function () {
        setTimeout(function() {
            $.App.loading('hide');
        }, 300);
    });
    
    $('.ajax-form').each(function () {
        $.App.ajaxForm($(this));
    });
    
    $.App.resize();
    $(window, '#container').resize(function () {
        $.App.resize();
    });
    
    $( '#sidebar-menu .dropdown > a' ).on( 'click' , function() {
        var li = $(this).parent();
        if ($.inArray( 'open-toggle' , li.attr( 'class' ).split(' ') ) !== -1) {
            li.find( '.sub-menu' ).slideUp(200, function() {
                li.removeClass( 'open-toggle' );
                if ( ! $.App.mobile ) {
                    $.App.resize();
                }
            });
        } else {
            $.each( $( '#sidebar-menu .open-toggle' ), function( key, value ) {
                if($.inArray( 'active' , $(this).attr( 'class' ).split(' ')) === -1) {
                    $(this).find( '.sub-menu' ).slideUp(200, function() {
                        $(this).parent().removeClass( 'open-toggle' );
                    });
                }
            });
            li.find( '.sub-menu' ).slideDown(function() {
                li.addClass( 'open-toggle' );
                if ( ! $.App.mobile ) {
                    $.App.resize();
                }
            });
        }
    });
    
    $('.multiple-0').select2({
        width: '100%'
    });
    $('.multiple-1').select2({
        allowClear: true,
        placeholder: 'wybierz pozycje',
        width: '100%'
    });
    
    $(document).on('click', '.ajax-href', function() {
        $.App.submit($(this), false, $(this).attr('href'), $(this).data('method'));
        return false;
    });
    
    bootbox.setLocale('pl');
    
    $.App.checkShowHide();
    $.App.finance();
    $.App.modalFooter();
};

/**
 * Loader ajax
 * @param {type} status
 */
$.App.loading = function(status) {
    (status === 'show') ? $('#loading').fadeIn('fast') : $('#loading').fadeOut('fast');
};

/**
 * Toastr info
 * @param {type} key
 * @param {type} val
 * @param {type} settings
 */
$.App.toastr = function (key, val, settings) {
    var options = {'closeButton': false, 'progressBar': true, 'positionClass': 'toast-top-full-width'};
    if (settings !== undefined) {
        $.extend(true, options, settings);
    }
    
    toastr.options = options;
    switch (key) {
        case 'info':
            toastr.info(val);
            break;
        case 'warning':
            toastr.warning(val);
            break;
        case 'success':
            toastr.success(val);
            break;
        case 'danger':
        case 'error':
            toastr.error(val);
            break;
    }
};

/**
 * Isset
 * @param {type} data
 * @returns {Boolean}
 */
$.App.isset = function(data) {
    return ( typeof( data ) !== 'undefined' && data !== null ) ? true : false;
};

/**
 * DateTime
 * @param {type} data
 * @param {type} format
 * @returns moment
 */
$.App.DateTime = function(data, format) {
    if ( typeof(format) == 'undefined' || format === null) {
        format = 'YYYY-MM-DD HH:ii:ss';
    }
    return ( typeof(data) !== 'undefined' && data !== null ) ? moment(data, format) : moment();
};

/**
 * Ajax form
 * @param {type} form
 * @returns {undefined}
 */
$.App.ajaxForm = function(form) {    
    form.formValidation({
        framework: 'bootstrap',
        locale: 'pl_PL',
        icon: {
            valid: null,
            invalid: null,
            validating: 'fa fa-refresh'
        }
    }).on('success.form.fv', function(ev) {
        ev.preventDefault();
        $.App.submit($(this));
    }).on('success.field.fv', function(e, data) {
        data.fv.disableSubmitButtons(false);
    }).on('err.validator.fv', function() {
        $.App.loading('hide');
    });
};

/**
 * Ajax submit
 * @param {type} $this
 * @param {type} $serialize
 * @param {type} $action
 * @param {type} $method
 */
$.App.submit = function($this, $serialize, $action, $method, $data) {
    var action = ($.App.isset($action)) ? $action : $this.attr('action');
    var method = ($.App.isset($method)) ? $method.toUpperCase() : $this.attr('method').toUpperCase();
    var serialize = ($.App.isset($serialize)) ? $serialize : true;
    
    if (typeof(tinyMCE) != 'undefined') {
        tinyMCE.triggerSave();
    }
    
    /**
     * bootstrap alert
     * @param {type} type
     * @param {type} text
     * @returns {String}
     */
    var alert = function(type, text) {            
        if ($.isArray(text)) {
            var callback = '';
            $.each(text, function(i, v) {
                callback += alert(type, v);
            });
            return callback;
        } else {
            if (type === 'error') {
                type = 'danger';
            }
            return '<div class="alert alert-' + type + '" role="alert">' + text + '</div>';
        }
    };
    
    /**
     * div.callback
     * @returns {undefined}
     */
    var findSelectorsCallback = function() {
        var selectors;
        
        if ($this.find('div.callback').length) {
            selectors = $this.find('div.callback');
        } else {
            if ($this.parents('.modal-dialog').find('div.callback').length) {
                selectors = $this.parents('.modal-dialog').find('div.callback');
            } else {
                selectors = $('div.callback').first();
            }
        }
        
        return selectors;
    };

    /**
     * div.callback - umieści info html
     * @param {type} callback
     * @returns {undefined}
     */
    var callbackSelectors = function(callback) {
        var selector = findSelectorsCallback();
            selector.html(callback);
        
        if ($this.parents('.modal-dialog').find('div.callback').length) {
            $('body .modal.in').animate({ scrollTop: 0 }, 'fast');
        } else {
            $('html, body').animate({
                scrollTop: selector.offset().top - 75
            }, 'fast');
        }
    };

    var action_callback = function(a, callback) {
        if ($.isArray(a)) {
            $.each(text, function(i, v) {
                action_callback(v, callback);
            });
        } else {
            eval(a);
        }
    };

    /**
     * Przetwarza odebrane dane
     * @param {type} c
     */
    var callback = function(c) {
        var div_callback = '';

        if ($.App.isset(c.info)) {
            div_callback += alert('info', c.info);
        }
        if ($.App.isset(c.warning)) {
            div_callback += alert('warning', c.warning);
        }
        if ($.App.isset(c.success)) {
            div_callback += alert('success', c.success);
        }
        if ($.App.isset(c.error)) {
            div_callback += alert('error', c.error);
        }
        
        if (div_callback.length > 0) {
            callbackSelectors(div_callback);
        } else {
            findSelectorsCallback().html('');
        }
        
        if ($.App.isset(c.action)) {
            if ($.App.isset(c.set_timeout)) {
                setTimeout(function() {
                    action_callback(c.action, c);
                }, c.set_timeout);
            } else {
                action_callback(c.action, c);
            }
        }
    };
    
    var ajax = {
        type: method,
        url: action,
        error: function(xhr, textStatus, error) {
            $.App.loading('hide');
        },
        data: {},
        success: function(c) {
            if ($.App.isset(c)) {
                callback(c);
            }
        }
    };
    
    if (serialize) {
        ajax.data = $this.serializeArray();
        if ($.App.isset($data)) {
            ajax.data.push( $data );
        }
    } else if ($.App.isset($data)) {
        ajax.data = $data;
    }
    
    if (method !== 'GET') {
        if (! $.App.isset(ajax.data._token)) {
            ajax.data._token = $.App.token;
        }
        ajax.data = $.param( ajax.data );
    }
    
    $.App.loading('show');
    $.ajax(ajax);
};

/**
 * Przekierowanie JS
 * @param {type} path
 */
$.App.redirect = function(path) {
    switch (path) {
        case 'reload':
            window.location = window.location.pathname + window.location.search + window.location.hash;
        break;
        
        case 'home':
            window.location = $.App.domain;
        break;
            
        default:
            path = path.replace($.App.domain, '');
            window.location = $.App.domain + path;
    }
};

/**
 * Zamiana na liczby i zaokragla do x miejsc po przecinku
 * @param {type} number
 * @param {type} k
 * @returns number
 */
$.App.decimal = function(number, k) {
    var factor;
    
    number = number.toString();
    number = number.replace(/\s/g, '').replace(',', '.');
    number = parseFloat(number) || 0;
    k = parseInt(k) || 0;
    
    factor = Math.pow(10, k+1);
    number = Math.round(Math.round(number * factor)/10);
    return number/(factor/10);
};

/**
 * Load Modal Footer
 */
$.App.modalFooter = function() {
    var $nextprev = false,
        $next = null,
        $prev = null;
    
    var nextprev = function(obj, name) {
        if (obj !== null && obj.length) {
            $('#modal-footer').find('.modal-' + name).prop('disabled', false);
        } else {
            $('#modal-footer').find('.modal-' + name).prop('disabled', true);
        }
    };
    
    $(document).on('click', '.modal-next', function() {
        $('#modal-footer').modal('hide');
        setTimeout(function() {
            $next.trigger('click');
        }, 400);
    });
    
    $(document).on('click', '.modal-prev', function() {
        $('#modal-footer').modal('hide');
        setTimeout(function() {
            $prev.trigger('click');
        }, 400);
    });
    
    $(document).on('click', 'a.load-modal-footer', function() {
        var $this = $(this);
        $('#modal-footer').load($this.attr('href'), function() {
            $('#modal-footer').modal();
            if ($this.hasClass('nextprev')) {
                $nextprev = true;
                $next = $this.parents('tr').next().find('.nextprev');
                $prev = $this.parents('tr').prev().find('.nextprev');
            } else {
                $nextprev = false;
                $next = null;
                $prev = null;
            }
        });
        return false;
    });
    
    $('#modal-footer').on('shown.bs.modal', function (e) {
        if ($nextprev) {
            nextprev($next, 'next');
            nextprev($prev, 'prev');
        }
        
        if ($('#modal-footer').find('.modal-dialog').hasClass('modal-tinymce')) {
            $.App.tinymceLoad();
        }
        
        $('#modal-footer .ajax-form').each(function () {
            $.App.ajaxForm($(this));
        });
        
        if ($('#modal-footer [data-toggle=confirmation]').length) {
            $.App.confirmation();
        }
    });
    
    $('#modal-footer').on('hidden.bs.modal', function() {
        if ($('#modal-footer').find('.modal-dialog').hasClass('modal-tinymce') && typeof(tinyMCE) != 'undefined') {
            tinyMCE.editors = [];
        }
    });
};

/**
 * TinyMCE
 * @param {type} readonly
 */
$.App.tinymceLoad = function(readonly) {
    
    var length_count = function(id, content) {
        var $length = content.replace(/(<([^>]+)>)/ig, '').replace(/[\t\n]+/g,' ').replace(/\s+/g, ' ').length;
        
        $(document).find('#' + id).parent()
                .find('.length_count span')
                .text('' + $length);
    };
    
    var options = {
        selector: 'textarea',
        language: 'pl',
        plugins: [
            'advlist autolink lists link image charmap preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media paste',
            'table'
        ],
        entity_encoding : 'raw',
        paste_auto_cleanup_on_paste : true,
        gecko_spellcheck: true,
        table_toolbar: "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
        toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
        height: '400px',
        content_css : $.App.domain + 'css/tinymce.css',
        setup: function(editor) {
            
            if ($('#' + editor.id).prop('readonly')) {
                editor.settings.readonly = true;
            }
            
            length_count(editor.id, $('#' + editor.id).val());
            editor.on('keyup', function(e) {
                length_count(this.id, tinyMCE.activeEditor.getContent());
            });
        }
    };
    
    if (readonly) {
        options.readonly = true;
    }
    
    setTimeout(function() {
        tinyMCE.init(options);
    }, 300);
};

/**
 * Zmiana rozmiaru okna
 */
$.App.resize = function() {
    if ( $(window).width() < 1120 ) {
        if ( ! $.App.mobile ) {
            $.App.mobile = true;
            $('body').addClass('mobile');
            $('#page').prepend( $('#sidebar-menu').detach() );
        }
    } else if ( $.App.mobile ) {
        $.App.mobile = false;
        $('body').removeClass('mobile');
        $('#page > .container > .row > .col-md-12').prepend( $('#sidebar-menu').detach() );
    }
    
    if ( ! $.App.mobile ) {
        var header = $('header').height();
        var footer = $('footer').height();
        var window_height = $(window).height();
        var sidebar_height = $('#sidebar-menu').height();

        if ((window_height - header - footer) >= sidebar_height) {
            $('#content').css('min-height', window_height - header);
        } else {
            $('#content').css('min-height', sidebar_height - footer);
        }
    }
};

$.App.checkShowHide = function() {
    var check = function($this) {
        var waper = $this.data('waper');
        $this.is(':checked') ?  $(waper).show() : $(waper).hide();
    };
    
    $(document).on('click', 'input.check-show-hide', function() {
        check($(this));
    });
    
    $('input.check-show-hide').each(function() {
        check($(this));
    });
};

/**
 * Finanse
 */
$.App.finance = function() {    
    /**
     * Vat z kwoty brutto
     * @param {type} $brutto
     */
    $.App.finance.vatOfBrutto = function($brutto) {
        return $.App.decimal(($.App.decimal($brutto, 2) * $.App.cost.vat) / (1 + $.App.cost.vat), 2);
    };

    var minPrice = function() {
        var textLength = $('input[name="text_length"]').val();

        $('#form-campaign').formValidation('removeField', $('input.count-netto').first());
        $('#form-campaign').formValidation('addField', $('input.count-netto').first());

        var zzs = $.App.decimal($.App.decimal($('input.count-netto').val(), 2) / $.App.decimal(textLength, 0) * 1000, 2);
        if ($.App.decimal(textLength, 0) < 1) {
            zzs = 0;
        }

        $('.zzs').text(zzs);

        var zzsNet = $.App.decimal($.App.decimal(zzs, 2) - $.App.finance.vatOfBrutto(zzs), 2);
        $('.zzs_net').text(zzsNet);
    }
    
    var countNetto = function($this) {

        var textsAmount = 0;

        $('input[name="text_number[]"]:visible').each(function () {
            textsAmount += parseInt($(this).val());
        });

        var amount = $this.val() * textsAmount;

        var netto = $.App.decimal($.App.decimal(amount, 2) - $.App.finance.vatOfBrutto(amount), 2);
        $('.gross').text(amount);
        $($this.data('waper')).text(netto);
    };
    $('input.count-netto').each(function() {
        countNetto($(this));
        minPrice();
    });
    $('input.count-netto').change(function() {
        countNetto($(this));
        minPrice();
    });
    $('input[name="text_length"]').each(function() {
        minPrice();
    });
    $('input[name="text_length"]').change(function() {
        minPrice();
    });
};

/**
 * Wczytuje formularz płatności
 * @param {type} selector
 * @param {type} options
 */
$.App.paymentLoad = function(selector, options) {
    $.get($.App.load_payment, options, function(callback) {
        $(selector).html(callback);
        $('#payment_coupon').click(function() {
            var coupon = $('.payment_coupon').val();
            if (coupon.length >= 3) {
                $.App.paymentLoad(selector, {payment_coupon: coupon});
            }
        });
    });
};

/**
 * Data Tables
 * @param {type} table
 * @param {type} options
 * @param {type} select
 * @param {type} forbiddenIds
 */
$.App.dataTable = function(table, options, select, forbiddenIds) {
    $.App.forbidden_ids = (typeof forbiddenIds !== 'undefined') ?  forbiddenIds : [];
    var table_key = table.replace(/[^a-zA-Z0-9]/g,'');
    
    if (select === true || select === 'checkbox') {
        $.App.rows_selected = [];
        $.App.rows_selected_all = false;
        
        options.columnDefs = [{
            'targets': 0,
            'searchable':false,
            'orderable':false,
            'className': 'dt-body-center',
            'render': function (data, type, full, meta) {
                return '<input type="checkbox" name="id[]" value="1">';
            }
        }];
        
        options.rowCallback = function(row, data, dataIndex) {
            
            var index = $.inArray(data.DT_RowId, $.App.rows_selected);
            if (select !== 'checkbox' && data.used.indexOf('success') !== -1) {
                $.App.forbidden_ids.push(data.DT_RowId);
            }

            if ($.inArray(data.DT_RowId, $.App.forbidden_ids) !== -1) {
                $(row).find('input[type="checkbox"]').prop('disabled', true);
                return;
            } else {
                $(row).find('input[type="checkbox"]').prop('disabled', false);
            }
            
            if ($.App.rows_selected_all === true || index !== -1){
                
                if ($.App.rows_selected_all && index === -1) {
                    $.App.rows_selected.push(data.DT_RowId);
                }
                
                $(row).find('input[type="checkbox"]').prop('checked', true);
            }
            /*else if (data.status === 0) {
                $(row).find('input[type="checkbox"]').remove();
            }*/
        };
        
        $(table).on('click', 'input[type="checkbox"]', function() {
            var rowId = parseInt($(this).parents('tr').first().attr('id'));
            var index = $.inArray(rowId, $.App.rows_selected);
            
            if ( this.checked && index === -1 ) {
                $.App.rows_selected.push(rowId);
            } else if ( ! this.checked && index !== -1){
                $.App.rows_selected.splice(index, 1);
            }
            
            if ( ! this.checked) {
                $.App.rows_selected_all = false;
                $(table + ' .select_all').prop('checked', false);
            }
        });
        
        $(table + ' .select_all').click(function() {
            $.App.rows_selected_all = this.checked;
            
            var input = $(table).find('input[type="checkbox"]');
            
            if ($.App.rows_selected_all) {
                
                $.each(input, function() {
                    var rowId = parseInt($(this).parents('tr').first().attr('id'));
                    var index = $.inArray(rowId, $.App.rows_selected);
                    
                    if (index === -1 && $.inArray(rowId, $.App.forbidden_ids) === -1) {
                        $.App.rows_selected.push(rowId);
                        $(this).prop('checked', $.App.rows_selected_all);
                    }
                });
                
            } else {
                $.App.rows_selected = [];
                input.prop('checked', $.App.rows_selected_all);
            }
        });
    }
    options.responsive = false;
    
    $.App.dataTable[table_key] = $(table).DataTable(options);

    var search = $(table + '_filter').find('input');
    search.unbind();
    search.on('keyup', function () {
        var $this = this.value;
        delay(function(){
            $.App.dataTable[table_key].search( $this ).draw();
        }, 1000);
    });
    $(table).parent().addClass('table-responsive');
    $('.white-tooltip').tooltip({container:'body'});

    return $.App.dataTable[table_key];
};

$.App.tableRow = function(callback) {
    
    $('#modal-footer').modal('hide');    
    
    setTimeout(function() {
        var $row = $(callback.table).find('tr#' + callback.id);

        if ($.App.isset(callback.class)) {
            $.each(callback.class, function(i, v) {
                $row.find('.' + i).html(v);
            });
        }

        if ($.App.isset(callback.remove)) {
            $row.remove();
        }

        if ($.App.isset(callback.action)) {
            eval(callback.action);
        }

        if ($.App.isset(callback.next) && callback.next) {

            var $next = $row.next().find('.nextprev');

            if ($next !== null && $next.length) {
                $next.trigger('click');
            }
        }
    }, 400);
};

/**
 * Confirmation
 * @returns {undefined}
 */
$.App.confirmation = function() {
    $('[data-toggle=confirmation]').confirmation({
        btnOkLabel: 'Tak',
        btnCancelLabel: 'Nie',
        btnOkIcon: 'fa fa-check',
        btnCancelIcon: 'fa fa-times',
        btnOkClass: 'btn-sm btn-primary',
        btnCancelClass: 'btn-sm btn-default',
        singleton: true
    });
    $('[data-toggle=confirmation]').on('confirmed.bs.confirmation', function(e) {
        $.App.submit($(this), false, $(this).data('url'), $(this).data('method'));
    });
};

$.App.uploadform = function() {
    Dropzone.autoDiscover = false;
    $('.uploadform').dropzone({
        url: $.App.domain + 'upload',
        acceptedFiles: 'image/*,.pdf,.doc,.docx,.txt,.odt,ods,.rar,.zip,.rtf,.xls,.xlsx',
        maxFilesize: 5,
        dictRemoveFile: 'Usuń plik',
        dictCancelUpload: 'Anuluj',
        dictFileTooBig: 'Plik jest zbyt duży ({{filesize}}MiB). Maksymalny rozmiar pliku: {{maxFilesize}}MB.',
        sending: function(file, xhr, formData) {
            formData.append('_token', $.App.token);
        },
        success: function(file, response) {            
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'file[]';
            input.value = response;
            file.previewTemplate.appendChild(input);
        },
        addRemoveLinks: true,
        removedfile: function(file) {
            var _ref;
            return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
        }        
    });
};

/**
 * ******************************
 * Pozostałe funkcje
 * ******************************
 */

/**
 * Opóźnienie
 * @type Function
 */
var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

(function ($) {
    'use strict';
    
    /**
    * Alert
    * @usage $("#div").bootboxAlert();
    */
    $.fn.bootboxAlert = function(options) {
        if ($.App.isset(options)) {
            if ($.App.isset(options.complete)) {
                var $complete = options.complete;
            }
        }
        return this.each(function () {
            var alert = $(this).data('alert');
            var url = $(this).data('url');
            var method = $(this).data('method');
            
            $(this).on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                bootbox.confirm(alert, function(result) {
                    if(result) {
                        $.App.submit($this, false, url, method);
                        if ($.App.isset($complete)) {
                            $complete($this);
                        }
                    }
                });
            });
        });
    };
    
    /**
    * Dropdown Toggle Input
    * @usage $("#div").dropdownToggleInput();
    */
    $.fn.dropdownToggleInput = function($click) {        
        return this.each(function () {
            var $this = $(this);
            $this.find('.selection').text( $this.find('input').val() );
            $this.find('li a').click(function() {
                $this.find('.selection').text( $(this).text() );
                $this.find('input').val( $(this).text() );
                if ($.App.isset($click)) {
                    $click($this, $(this));
                }
            });
        });
    };
    
}($));

/**
 * Console log
 * @param {type} $data
 */
function vd($data) {
    console.log($data);
}

/**
 * php sprintf
 * @param {type} format
 */
function sprintf( format ) {
    for( var i=1; i < arguments.length; i++ ) {
        format = format.replace( /%s/, arguments[i] );
    }
    return format;
}